import React from 'react'
import { Link } from 'react-router-dom'

export default function MenuWidget({ menuItems, menuHeading, variant, fotterLink }) {


  const changetitle = (title) => {
    if (title) {

      const newtitle = title.split(" ").join("-").toLowerCase();
      return newtitle.replace(/[^a-z0-9-]/g, 'and');
    }
  }
  return (
    <>
      {menuHeading && <h2 className="cs-widget_title">{menuHeading}</h2>}
      <ul className={`${variant ? `cs-menu_widget ${variant}` : 'cs-menu_widget cs-style1'} cs-mp0`}>
        {menuItems && menuItems?.slice(0, 4)?.map((item, index) => (
          <li  key={index}>
            {fotterLink === undefined ? <Link to={`/service/${item?.id}`}>{item.title}</Link> : item?.published ? item?.show_on_footer ? <Link to={`more/${changetitle(item?.title)}:${item?.id}`} >{item?.title}</Link> : null : null}
          </li>
        ))}
      </ul>
    </>
  )
}
