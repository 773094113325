import React from 'react'
import Div from '../Div'
import Spacing from '../Spacing'
import Pagination from '../Pagination'
import Sidebar from '../Sidebar'
import { FetchGetRequest } from '../api'
import { useState } from 'react'
import { useEffect } from 'react'
import Loading, { SkeletonLoading } from "../Loading/index"
import PostStyle2 from '../Post/PostStyle2'
import { useParams } from 'react-router-dom'

const FilterPage = () => {

    const [BlogCategory, setBlogCategory] = useState();
    const [BlogData, setBlogData] = useState();
    const [TagData, setTagData] = useState();
    const [FilterData, setFilterData] = useState();
    const { filterId, filterType } = useParams();
    const id = filterId.split(":")[1];
    const type = filterType.split(":")[1];
    const [loadingState, setLoadingState] = useState(true);

    useEffect(() => {

        const GetData = async () => {
            const BlogCategory = await FetchGetRequest(`blog-categories`)
            setBlogCategory(BlogCategory)

            const BlogData = await FetchGetRequest(`blogs`)
            setBlogData(BlogData);

            const TagData = await FetchGetRequest(`tags`)
            setTagData(TagData);
        }

        GetData();
    }, []);


    useEffect(() => {
        const GetData = async () => {
            const FilterData = await FetchGetRequest(`blogs/${id}/${type}`);
            setFilterData(FilterData)
        }

        GetData();
    }, [id, type])

    useEffect(() => {
        if (!BlogCategory || !BlogData || !TagData || !FilterData) {
            setLoadingState(true);
        } else {
            setLoadingState(false);
        }
    }, [BlogCategory, BlogData, TagData, FilterData])



    // if (!BlogCategory || !BlogData || !TagData || !FilterData) {
    //     return <Loading />
    // }

    return (
        <>

            <Spacing lg='150' md='80' />
            <Div className="container">
                <Div className="FilterHeader">
                    {loadingState ? <SkeletonLoading dynamicStyle={{ marginRight: "auto" }} count={1} width={'400px'} height={'60px'} /> :
                        <h3>{type} Results: <span className=''> {id}</span></h3>}
                </Div>
                <Div className="row">
                    <Div className="col-lg-8">
                        {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} count={1} width={'100%'} height={'600px'} /> :
                            FilterData && FilterData?.results?.length === 0 ?
                                <Div className="noDataFound">
                                    <h4>No Blogs Found!</h4>
                                </Div>
                                : FilterData?.results?.map((item, index) => (
                                    <Div key={index}>
                                        <PostStyle2
                                            thumb={item.banner_image}
                                            title={item.title}
                                            subtitle={item.content}
                                            date={item.created_at}
                                            category={item.category}
                                            categoryHref={"/blog"}
                                            href={`/blog/blog-details:${item?.id}`}
                                        />
                                        {BlogData && BlogData?.results?.length > index + 1 && <Spacing lg='95' md='60' />}
                                    </Div>
                                ))}
                        <Spacing lg='60' md='40' />
                        {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} count={1} width={'100%'} height={'150px'} /> :
                            <Pagination filterId={id} filterType={type} setBlogData={setFilterData} BlogData={FilterData} />}
                    </Div>
                    <Div className="col-xl-3 col-lg-4 offset-xl-1">
                        <Spacing lg='0' md='80' />
                        {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} count={1} width={'300px'} height={'100%'} /> : <Sidebar BlogData={BlogData?.results} TagData={TagData} BlogCategory={BlogCategory} />}
                    </Div>
                </Div>
            </Div>
        </>
    )
}

export default FilterPage