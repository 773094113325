import React from 'react'
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';

export default function ContactInfoWidget({ withIcon, title, SettingsData }) {


  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <a href={`tel:${SettingsData && SettingsData?.phone_number}`}><li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span> : ''}
          {"+" +  SettingsData && SettingsData?.phone_number}
        </li></a>
        <Spacing lg={"20"} md={"10"} />
        <a href={`mailto:${SettingsData?.email}`}><li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:envelope" /></span> : ''}
          {SettingsData?.email}
        </li></a>
        <Spacing lg={"20"} md={"10"} />
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
          {SettingsData?.address}
        </li>
      </ul>
    </>
  )
}
