import React, { useEffect, useState } from 'react';
import { checkLength, pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Button from '../Button';
import Card from '../Card'
import './about.scss'
import { FetchGetRequest } from '../api';
import { SkeletonLoading } from '../Loading';

export default function AboutPage() {
  pageTitle('About');


  const [AboutUsData, setAboutUsData] = useState({
    MissionData: undefined,
    AboutData: undefined,
    VisionData: undefined,
    OurGoalsData: undefined,
  });
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    const GetData = async () => {
      const AboutData = await FetchGetRequest(`about-us`)
      setAboutUsData((item) => ({ ...item, AboutData }))

      const MissionData = await FetchGetRequest(`mission`)
      setAboutUsData((item) => ({ ...item, MissionData }))

      const VisionData = await FetchGetRequest(`vision`)
      setAboutUsData((item) => ({ ...item, VisionData }))

      const OurGoalsData = await FetchGetRequest(`goals`)
      setAboutUsData((item) => ({ ...item, OurGoalsData }))
    }

    GetData();
  }, [])



  useEffect(() => {
    if (!AboutUsData.AboutData || !AboutUsData.MissionData || !AboutUsData.OurGoalsData || !AboutUsData.OurGoalsData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, AboutUsData])



  return (
    <>
      {/* Start About Section */}
      <Spacing lg="150" md="100" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-7">
            {loadingState ? <SkeletonLoading width={"600px"} height={"90px"} /> : <SectionHeading
              title={AboutUsData?.AboutData && AboutUsData?.AboutData?.results[0]?.page_heading}
              subtitle={checkLength(AboutUsData?.AboutData?.results) ? AboutUsData?.AboutData?.results[0]?.page_title : ""}
              varient="primary-dark"
            >
            </SectionHeading>}
            <Spacing lg="15" md="10" />
            {loadingState ? <SkeletonLoading width={"600px"} height={"400px"} /> : <p className="center_data cs-m0 text-bold">
              {checkLength(AboutUsData?.AboutData?.results) ? AboutUsData?.AboutData?.results[0]?.page_description : ""}
            </p>}
            <Spacing lg="30" md="30" />
            <Div className="cs-separator cs-accent_bg"></Div>
            <Spacing lg="25" md="40" />
          </Div>
          <Div className="col-lg-5 offset-xl-1">
            {loadingState ? <SkeletonLoading width={"500px"} height={"600px"} /> : <img
              src={checkLength(AboutUsData?.AboutData?.results) ? AboutUsData?.AboutData?.results[0]?.feature_image : ''}
              alt="About"
              className="w-100 cs-radius_15"
            />}
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />


      {/* Start Our Mission */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              {loadingState ? <SkeletonLoading marginBottom={"0"} width={"400px"} height={"400px"} /> : <img
                src="/images/mission.png"
                alt="About"
                className="w-100 cs-radius_15"
              />}
              {/* </Div> */}
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-7 col-lg-6 our_mission">
            {loadingState ? <SkeletonLoading count={5} direction={"column"} width={"600px"} height={"350px"} marginBottom={"10px"} /> : <SectionHeading
              title="Our Mission"
              subtitle="Why Choose Us"
            >
              {AboutUsData?.MissionData?.results?.map((item, index) => (
                <div key={index}>
                  <Spacing lg="30" md="20" />
                  <Button btnLink='#' btnText={item?.title} variant='cs-type2 mission_item' />
                </div>
              ))
              }
              <Spacing lg="30" md="30" />
            </SectionHeading>}
          </Div>
        </Div>
      </Div>
      {/* End Our Mission */}
      {/* Start Our values */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-4">
            {loadingState ? <SkeletonLoading count={2} width={"400px"} height={"150px"} /> : <SectionHeading
              title='Our Values'
              subtitle='Why we'
            />}
            <Spacing lg='90' md='45' />
          </Div>

          <Div className='col-xl-8'>
            <Div className='row'>
              <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
              <Div className='col-lg-3 col-sm-6'>
                {loadingState ? <SkeletonLoading width={"200px"} height={"200px"} /> : <Card
                  title='Brilliance'
                  alt='Service'
                />}
                <Spacing lg='0' md='30' />
              </Div>
              <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
              <Div className='col-lg-3 col-sm-6'>
                {loadingState ? <SkeletonLoading width={"200px"} height={"200px"} /> :
                  <Card
                    title='Grace'
                    alt='Service'
                  />}
                <Spacing lg='0' md='30' />
              </Div>
              <Div className='col-lg-3 col-sm-6'>
                {loadingState ? <SkeletonLoading width={"200px"} height={"200px"} /> : <Card
                  title='Unlocking Potential '
                  alt='Service'
                />}
                <Spacing lg='0' md='30' />
              </Div>
              <Div className='col-lg-3 col-sm-6  cs-hidden_mobile'></Div>
              <Div className='col-lg-3 col-sm-6'>
                {loadingState ? <SkeletonLoading width={"200px"} height={"200px"} /> : <Card
                  title='Ethics'
                  alt='Service'
                />}
                <Spacing lg='0' md='30' />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="100" md="80" />
      {/* End Our values */}
      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 our_mission">
            {loadingState ? <SkeletonLoading count={5} direction={"column"} width={"500px"} height={"300px"} /> : <SectionHeading
              title="Our Goals"
              subtitle="Our Plans"
            >

              {
                AboutUsData?.OurGoalsData?.results?.map((item, index) => (
                  <div key={index}>
                    <Spacing lg="30" md="20" />
                    <Button btnLink='#' btnText={item?.title} variant='cs-type2 mission_item' />
                    <Spacing lg='20' md='10' />
                  </div>
                ))
              }
            </SectionHeading>}
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              {loadingState ? <SkeletonLoading width={"450px"} height={"500px"} /> : <img
                src="/images/goals.png"
                alt="About"
                className="w-100 cs-radius_15"
              />}
              {/* </Div> */}
            </Div>
            <Spacing lg="0" md="40" />
          </Div>

        </Div>
      </Div >
      {/* End Why Choose Section */}
      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              {loadingState ? <SkeletonLoading width={"450px"} height={"500px"} /> :
                <img
                  src="/images/vision.png"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              }
              {/* </Div> */}
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-7 col-lg-6 our_mission">
            {loadingState ? <SkeletonLoading count={5} direction={"column"} width={"500px"} height={"300px"} /> : <SectionHeading
              title="Our Vision"
              subtitle="Why Choose Us"
            >
              {
                AboutUsData?.VisionData?.results?.map((item, index) =>
                  <div key={index} className="">
                    <Spacing lg="30" md="20" />
                    <Button btnLink='#' btnText={item?.title} variant='cs-type2 mission_item' />
                  </div>
                )
              }

            </SectionHeading>}
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}
    </>
  );
}
