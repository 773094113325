import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import './header.scss';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Div from '../Div';
import { FetchGetRequest, FetchPostRequest } from '../api';
import toast from 'react-hot-toast';
import { FaSun, FaMoon } from 'react-icons/fa';
import { checkLength } from '../../helper';
import { SkeletonLoading } from '../Loading';


export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  const [SiteSettingsData, setSiteSettingsData] = useState();
  const [emailData, setEmailData] = useState("");

  const [theme, setTheme] = useState("light");
  const [loadingState, setLoadingState] = useState(true);


  useEffect(() => {
    const defaultDark = window.matchMedia('(prefers-color-schema:dark)').matches ? "dark" : "light";
    const storeData = localStorage.getItem("theme");
    setTheme(storeData || defaultDark);
  }, [])

  const ToggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  }

  useEffect(() => {
    if (typeof window === "undefined") return;
    const html = document.getElementsByTagName("html")[0];
    html.setAttribute("data-theme", theme);
  }, [theme]);

  useEffect(() => {
    const getData = async () => {
      const SiteSettingsData = await FetchGetRequest(`site-settings`)
      setSiteSettingsData(SiteSettingsData);
    }

    getData();
  }, [])


  const postEmail = async (e) => {
    e.preventDefault();
    const email = { "email": emailData }
    const PostContact = await FetchPostRequest("newslettersubscription", email);
    if (PostContact.status === 201) {
      setEmailData("")
      return toast.success("Message successfully submited.")
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  const location = useLocation
  const path = location().pathname;



  useEffect(() => {
    if (!SiteSettingsData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, SiteSettingsData])



  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${variant ? variant : ''
          } cs-sticky_header ${path !== "/" ? "cs-sticky_header_active" : ""} ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                {loadingState ? <SkeletonLoading marginBottom={"0"} width={"70px"} height={"70px"} /> : <Link className="cs-site_branding" to="/">
                  {isSticky ? <img src={checkLength(SiteSettingsData) ? SiteSettingsData[0]?.logo_white : ""} alt="Logo" /> :
                    <img src={checkLength(SiteSettingsData) ? SiteSettingsData[0]?.logo_color : ""} alt="Logo" />
                  }
                </Link>}
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li >
                      <NavLink to="/" onClick={() => setMobileToggle(false)}>
                        Home
                      </NavLink>

                    </li>

                    <li>
                      <NavLink
                        to="about"
                        onClick={() => setMobileToggle(false)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li >
                      <NavLink
                        to="service"
                        onClick={() => setMobileToggle(false)}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li >
                      <NavLink
                        to="portfolio"
                        onClick={() => setMobileToggle(false)}
                      >
                        Portfolio
                      </NavLink>

                    </li>
                    <li >
                      <NavLink to="blog" onClick={() => setMobileToggle(false)}>
                        Blog
                      </NavLink>

                    </li>
                    <li >
                      <NavLink to="contact" onClick={() => setMobileToggle(false)}>
                        Contact Us
                      </NavLink>

                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  {loadingState ? <SkeletonLoading Radius={"50%"} marginBottom={"0"} width={"60px"} height={"60px"} /> : <>
                    <span
                      className="cs-icon_btn"
                      onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                    >
                      <span className="cs-icon_btn_in">
                        <span />
                        <span />
                        <span />
                        <span />
                      </span>
                    </span>
                  </>
                  }
                </Div>
                <div className="iconModeContainer">
                  {loadingState ? <SkeletonLoading Radius={"50%"}  marginBottom={"0"} width={"60px"} height={"60px"} /> : <div onClick={ToggleTheme}>
                    {theme === "dark" ?
                      <FaSun className={`ChangeModeColor iconStyle`} />
                      :
                      <FaMoon className={`ChangeModeColor iconStyle`} />
                    }
                  </div>}
                </div>

              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div
        className={
          sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Link className="cs-site_branding" to="/">
            <img src={SiteSettingsData && SiteSettingsData[0]?.logo_color} alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Do you have a project in your <br /> mind? Keep connect us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget SettingsData={checkLength(SiteSettingsData) ? SiteSettingsData[0] : {}} title="Contact Us" withIcon />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe"
              subtitle="Get latest updates from us."
              placeholder="examle@gmail.com"
              setEmailData={setEmailData}
              emailData={emailData}
              postEmail={postEmail}
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget SettingsData={SiteSettingsData && SiteSettingsData[0]} />
          </Div>
        </Div>
      </Div>
    </>
  );
}
