import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { checkLength, pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { FetchGetRequest, FetchPostRequest } from '../api';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import { SkeletonLoading } from '../Loading';

export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [SiteSettingsData, setSiteSettingsData] = useState();
  const [captchaData, setCaptchaData] = useState(null);
  const [isButtonOn, setIsButtonOn] = useState(true);
  const [contactData, setContactData] = useState({
    full_name: "",
    email: "",
    project_type: "",
    mobile: "",
    message: "",
  });
  const captch = useRef(null);
  const [loadingState, setLoadingState] = useState(true);

  const setContactDetails = (e) => {
    setContactData((item) => ({ ...item, [e.target.name]: e.target.value }))
  }

  useEffect(() => {
    const getData = async () => {
      const SiteSettingsData = await FetchGetRequest(`site-settings`)
      setSiteSettingsData(SiteSettingsData);
    }

    getData();
  }, [])


  const PostData = async (e) => {
    e.preventDefault();

    const PostContact = await FetchPostRequest("inquiries", contactData);
    if (PostContact?.data) {
      setContactData({
        full_name: "",
        email: "",
        project_type: "",
        mobile: "",
        message: "",
      })
      captch.current.reset();
      return toast.success("Message Successfully submited.")
    }
  }


  const CaptchaChanged = (e) => {
    setCaptchaData(e);
  }

  useEffect(() => {
    if (!captchaData) {
      setIsButtonOn(true)
    } else {
      setIsButtonOn(false);
    }
  }, [captchaData])


  useEffect(() => {
    if (!SiteSettingsData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, SiteSettingsData])


  const googleIframe = checkLength(SiteSettingsData) ? SiteSettingsData[0]?.google_iframe : ""
  return (
    <>
      <Spacing lg="150" md="100" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            {loadingState ? <SkeletonLoading count={3}  width={"400px"} height={"180px"} /> : <ContactInfoWidget SettingsData={checkLength(SiteSettingsData) ? SiteSettingsData[0] : {}} withIcon />}
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={PostData} action="#" className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input required name='full_name' value={contactData.full_name} onChange={(e) => { setContactDetails(e) }} type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input required name='email' value={contactData?.email} onChange={(e) => { setContactDetails(e) }} type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input required name='project_type' value={contactData?.project_type} onChange={(e) => { setContactDetails(e) }} type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input required name='mobile' value={contactData?.mobile} onChange={(e) => { setContactDetails(e) }} type="number" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  required
                  value={contactData?.message} onChange={(e) => { setContactDetails(e) }}
                  name='message'
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <Spacing lg="25" md="25" />
                <ReCAPTCHA ref={captch}  onChange={CaptchaChanged} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button disabled={isButtonOn} className={`cs-btn ${isButtonOn ? "disableBtn" : ""} cs-style1`}>
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
      {loadingState ? <SkeletonLoading height={"600px"} /> :  <div style={{ width: "100%", height: "100%" }} dangerouslySetInnerHTML={{ __html: googleIframe }} />}
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
