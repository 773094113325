import React from 'react'
import { Icon } from '@iconify/react';
import Div from '../Div';


export default function SocialWidget({ SettingsData }) {

  return (
    <Div className="cs-social_btns cs-style1">
      <a target='_blank' rel='noreferrer' href={SettingsData?.facebook} className="cs-center">
        <Icon icon="fa6-brands:facebook" />
      </a>
      <a target='_blank' rel='noreferrer' href={SettingsData?.instagram} className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </a>
    </Div>
  )
}
