import { Icon } from '@iconify/react'
import React from 'react'
import { FetchByPageNo, FetchNextData, FetchPreviousData, trimUrl } from '../../helper'

export default function Pagination({ BlogData, setBlogData, filterId, filterType }) {

  const blogPerPage = 10;



  const checkPageNo = (data) => {
    if (data?.next) {
      return parseInt(trimUrl("page=", data?.next, 1)) - 1
    } else if (!data?.next && data?.previous) {
      if (BlogData?.count <= 4) return 2
      return parseInt(trimUrl("page=", data?.previous, 1)) + 1
    } else {
      return 1;
    }
  }

  const scrollToTop = () => {
    if (typeof window === "undefined") return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const totalPageShow = () => {
    if (BlogData) {
      return Math.floor(BlogData?.count / blogPerPage + BlogData?.count % 2);
    } else {
      return;
    }
  }


  return (
    <ul style={{ display: `${BlogData && BlogData?.results?.length === 0 ? "none" : ""}` }} className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
      <li onClick={() => {
        if (BlogData?.previous) {
          FetchPreviousData(BlogData, setBlogData)
          scrollToTop()
        }
      }}
      >

        <button disabled={!BlogData?.previous} className={`${!BlogData?.previous ? "" : "active"} cs-pagination_item cs-center`}>
          <Icon icon="akar-icons:chevron-left" />
        </button>
      </li>
      {totalPageShow() && Array(totalPageShow())?.fill(totalPageShow())?.map((item, index) => (
        <li style={{ cursor: "pointer" }} onClick={() => {
          if (BlogData?.count > 2) {
            FetchByPageNo(index + 1, setBlogData, filterId, filterType)
            scrollToTop()
          }
        }
        } key={index}>
          <span className={` cs-pagination_item cs-center  
          ${checkPageNo(BlogData) === index + 1 ? "active" : ""} `} >{index + 1}</span>
        </li>
      ))
      }
      <li onClick={() => {
        if (BlogData?.next) {
          FetchNextData(BlogData, setBlogData)
          scrollToTop()
        };
      }}
        className={``}
      >
        <button disabled={!BlogData?.next} className={` ${!BlogData?.next ? "" : "active"} cs-pagination_item cs-center `}>
          <Icon icon="akar-icons:chevron-right" />
        </button>
      </li>
    </ul>
  )
}
