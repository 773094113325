import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import ModalImage from 'react-modal-image';
import './portfolio.scss';


export default function Portfolio({ href, src, variant }) {
  const handleClick = (e) => {
    e.preventDefault();
  };

  const [imageLoad, setImageLoad] = useState(true);

  return (
    <Link
      to={href}
      className={`cs-portfolio cs-bg ${variant ? variant : 'cs-'}`}
    >
      <>
        <Div className="cs-portfolio_hover" onClick={handleClick} />
        <Div className="cs-lightbox_item">
          <ModalImage
            small={src}
            large={src}
            alt='Portfolio image'
            onLoad={setTimeout(() => {
              setImageLoad(false);
            }, 1500)}
          />


        </Div>
        
           :

          <Div
            className="cs-portfolio_bg cs-bg"
          >
            <img loading='lazy' style={{display:`${imageLoad ? "none" : "block" }`, width:"100%", height:"100%", objectFit:"cover"}} src={src} alt="portfolio" />
          </Div>
      </>

    </Link>
  );
}
