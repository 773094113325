import React, { useEffect, useState } from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import { FetchGetRequest, FetchPostRequest } from '../api'
import toast from 'react-hot-toast'
import { checkLength } from '../../helper'
import { SkeletonLoading } from '../Loading'

export default function Footer() {


  const [SiteSettingsData, setSiteSettingsData] = useState();
  const [fotterServices, setfotterServices] = useState();
  const [emailData, setEmailData] = useState("");
  const [MorePages, setMorePages] = useState();
  const [loadingState, setLoadingState] = useState(true);





  const postEmail = async (e) => {
    e.preventDefault();
    const email = { "email": emailData }
    const PostContact = await FetchPostRequest("newslettersubscription", email);
    if (PostContact.status === 201) {
      setEmailData("")
      return toast.success("Message Successfully submited.")
    }
  }

  useEffect(() => {
    const getData = async () => {
      const SiteSettingsData = await FetchGetRequest(`site-settings`)
      setSiteSettingsData(SiteSettingsData);

      const fotterServices = await FetchGetRequest(`services`)
      setfotterServices(fotterServices);

      const MorePages = await FetchGetRequest(`pages`)
      setMorePages(MorePages);
    }

    getData();
  }, [])


  useEffect(() => {
    if (!SiteSettingsData || !fotterServices || !MorePages) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, SiteSettingsData, fotterServices, MorePages])


  return (
    <footer className="cs-footer">
      <Div className="cs-footer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                {loadingState ? <SkeletonLoading width={"150px"} height={"150px"} /> : <TextWidget
                  logoSrc={checkLength(SiteSettingsData) ? SiteSettingsData[0]?.logo_color : ""}
                  logoAlt={checkLength(SiteSettingsData) ? SiteSettingsData[0]?.name : ""}
                  text={checkLength(SiteSettingsData) ? SiteSettingsData[0]?.footer_tagline : ""}
                />}
                {loadingState ? <SkeletonLoading count={2} direction={"row"} Radius={"50%"} width={"120px"} height={"50px"} /> : <SocialWidget SettingsData={SiteSettingsData && SiteSettingsData[0]} />}
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                {loadingState ? <SkeletonLoading count={5} Radius={"0"} width={"250px"} height={"200px"} /> :
                  <MenuWidget menuItems={fotterServices?.results} menuHeading='Services' />}
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                {loadingState ? <SkeletonLoading count={3} Radius={"0"} width={"250px"} height={"130px"} /> : <ContactInfoWidget SettingsData={checkLength(SiteSettingsData) ? SiteSettingsData[0] : {}} title='Contact Us' />}
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                {loadingState ? <SkeletonLoading count={3} Radius={"0"} width={"250px"} height={"200px"} /> : <Newsletter
                  title='Subscribe'
                  subtitle='Get latest updates from us.'
                  placeholder='example@gmail.com'
                  setEmailData={setEmailData}
                  emailData={emailData}
                  postEmail={postEmail}
                />}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            {loadingState ? <SkeletonLoading direction={"row"} width={"400px"} height={"40px"} /> : <Div className="cs-copyright">Copyright © 2022 Aarambha IT Research Center.</Div>}
          </Div>
          <Div className="cs-bottom_footer_right">
            {loadingState ? <SkeletonLoading count={2} direction={"row"} Radius={"0"} width={"350px"} height={"50px"} /> : <MenuWidget menuItems={MorePages?.results} fotterLink={'/'} MorePages={MorePages} variant='cs-style2' />}
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
