import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'

export default function TagWidget({title, data}) {


  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <Div className="tagcloud">
        {data && data?.map((tag, index)=> (
          <Link to={`/blog/filterData/tag:${tag?.tag_name}/type:${"tags"}`} className="tag-cloud-link" key={index}>{tag?.tag_name}</Link>
        ))}
      </Div>
    </>
  )
}
