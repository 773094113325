import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function SearchWidget({ title }) {

  const [searchData, setSearchData] = useState("")
  const link = useRef(null);


  useEffect(() => {

    const SearchInEnter = (e) => {
      if (e.key === "Enter") {
        if (link.current && searchData !== "") {
          link.current.click()
        }
      }

    }
    if (typeof document === "undefined") return;
    document.addEventListener("keydown", SearchInEnter)

    return () => document.removeEventListener("keydown", SearchInEnter)
  }, [searchData])

  const LinkClicked = () => {
    setSearchData("")
  }



  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <div className="cs-sidebar_search">
        <input type="text" onChange={(e) => { setSearchData(e.target.value) }} value={searchData} placeholder="Search..." />
        {searchData === "" ?
          <button onClick={LinkClicked} className="cs-sidebar_search_btn">
            <Icon icon="material-symbols:search-rounded" />
          </button>
          :
          <Link onClick={LinkClicked} ref={link} to={`/blog/filterData/search:${searchData}/type:${"search"}`} className="cs-sidebar_search_btn">
            <Icon icon="material-symbols:search-rounded" />
          </Link>}
      </div>
    </>
  )
}
