import React from 'react'
import parse from 'html-react-parser';
import Button from '../Button'
import Spacing from '../Spacing'
import Div from '../Div';
import { SkeletonLoading } from '../Loading';

export default function SectionHeading({ title, subtitle, btnLink, btnText, variant, children, loadingState }) {
  return (
    <Div className={variant ? `cs-section_heading ${variant}` : `cs-section_heading cs-style1`}>

      {loadingState ? <SkeletonLoading count={2}/> : subtitle && (
        <h3 className="cs-section_subtitle">{parse(subtitle)}</h3>
      )}
      {loadingState ? <SkeletonLoading count={2}/> : subtitle && (
        <h2 className="cs-section_title">{parse(title)}</h2>
      )}
      {children}
      <Spacing lg='45' md='20' />
      {loadingState ? <SkeletonLoading count={2}/> : btnText && (
          <Button btnLink={btnLink} btnText={btnText} />
      )}


    </Div>
  )
}
