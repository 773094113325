import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { FetchGetRequest } from '../api'
import { SkeletonLoading } from "../Loading/index"

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const { serviceDetailsId } = useParams();
  const [serviceDetail, setServiceDetail] = useState();
  const [loadingState, setLoadingState] = useState(true);

  const serviceId = serviceDetailsId;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const getData = async () => {
      const serviceData = await FetchGetRequest(`services/${serviceId}`)
      setServiceDetail(serviceData)
    }

    getData()
  }, [serviceId]);




  useEffect(() => {
    if (!serviceDetail) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [serviceDetail])



  return (
    <>

      <Spacing lg='145' md='80' />
      <Div className="container">
        {
          loadingState ? <SkeletonLoading dynamicStyle={{margin:"auto"}} count={1} width={'100%'} height={'150px'} /> :
            <SectionHeading
              title={serviceDetail?.title}
              subtitle='What we do'
              variant='cs-style1 text-center'
            />
        }

      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container">

        <Div className="row align-items-center">
          <Div className="col-xl-4 col-lg-6">
            {loadingState ? <SkeletonLoading  dynamicStyle={{margin:"auto"}} count={1}  width={'500px'} height={'500px'}/> :
              <Div className="cs-radius_15 cs-shine_hover_1">
                <img src={serviceDetail?.image} alt="Service" className='cs-radius_15 w-100' />
              </Div>}
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-7 offset-xl-1">
            <Spacing lg='50' md='30' />
            <Div className="row">
              {loadingState ? <SkeletonLoading dynamicStyle={{margin:"auto"}} count={1}  width={'600px'} height={'400px'}/> :
                <Div className="col-lg-12">
                  <p className='text-bold'>
                    {serviceDetail?.description}

                  </p>
                </Div>}
              <Spacing lg='0' md='10' />
            </Div>
          </Div>
        </Div>
        <Spacing lg='120' md='50' />
        <Div className="row">

        </Div>
      </Div>
      {/* <Spacing lg='150' md='80' /> */}
    </>
  )
}
