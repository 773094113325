import axios from "axios"
import toast from "react-hot-toast";


export const FetchGetRequest = async (endpoint) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BURNING_TWIG_API_URL}/${endpoint}/`);
        const data = res?.data;
        return data;
    } catch (err) {
        console.error(err.message);
        return;
    }
}

export const FetchPostRequest = async (endpoint, contactData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BURNING_TWIG_API_URL}/${endpoint}/`, contactData);
        const data = res;
        return data;
    } catch (err) {
        toast.error(err && err?.response?.data?.email[0])
        return err.message;
    }
}


