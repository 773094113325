import React from 'react'
import Div from '../Div'
import RecentPost from '../Widget/RecentPost'
import SearchWidget from '../Widget/SearchWidget'
import SideMenuWidget from '../Widget/SideMenuWidget'
import TagWidget from '../Widget/TagWidget'
import Loading from "../Loading/index";

export default function Sidebar({ BlogData, BlogCategory, TagData }) {


  if(!BlogData || !BlogCategory || !TagData) return <Loading/>

  return (
    <>
      <Div className="cs-sidebar_item">
        <SearchWidget title='Search' />
      </Div>
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Categories' data={BlogCategory} />
      </Div>
      <Div className="cs-sidebar_item">
        <RecentPost title='Archives' data={BlogData} />
      </Div>
      <Div className="cs-sidebar_item">
        <TagWidget title='Tags' data={TagData} />
      </Div>
    </>
  )
}
