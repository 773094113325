import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import Portfolio2 from '../Portfolio/Portfolio2';
import Portfolio3 from '../Portfolio/Portfolio3';
import { FetchGetRequest } from '../api'
import { SkeletonLoading } from '../Loading'


export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  const [ServicesData, setServicesData] = useState();
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    const GetData = async () => {
      const Services = await FetchGetRequest(`services`)
      setServicesData(Services);

    }

    GetData();
  }, [])



  useEffect(() => {
    if (!ServicesData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, ServicesData])


  return (
    <>

      <Spacing lg='140' md='100' />
      {/* Start What we do Section */}
      <Div className="container">
        {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} displays={"block"} width={"500px"} height={"80px"} /> : <SectionHeading
          title="Our Services"
          subtitle="What we do"
          variant="cs-style1 text-center"
        />}
      </Div>

      {/* Start Portfolio Section */}
      <Spacing lg="100" md="80" />
      {ServicesData?.results?.map((item, index) =>
        index % 2 === 0 ? (
          <Div key={index}>
            <Portfolio2
              title={item.title}
              subtitle={item.description}
              btnText={"Read More"}
              btnLink={`/service/${item?.id}`}
              imageUrl={item.image}
              category={item.category}
              loadingState={loadingState}
            />
            <Spacing lg="100" md="70" />
          </Div>
        ) : (
          <Div key={index}>
            <Portfolio3
              title={item.title}
              subtitle={item.description}
              btnText={"Read More"}
              btnLink={`/service/${item.id}`}
              imageUrl={item.image}
              category={item.category}
              loadingState={loadingState}
            />
            <Spacing lg="100" md="70" />
          </Div>
        ),
      )}
      {/* End Portfolio Section */}
      {/* <Link to={`/blog/${post.id}`}>{post.title}</Link> */}

      <Spacing lg="90" md="80" />
      {/* End What we do Section */}
    </>
  )
}
