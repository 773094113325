import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Pagination from '../Pagination'
import PostStyle2 from '../Post/PostStyle2'
import Div from '../Div'
import Sidebar from '../Sidebar'
import Spacing from '../Spacing'
import { FetchGetRequest } from '../api'
import { SkeletonLoading } from '../Loading'

export default function BlogPage() {
  pageTitle('Blog');



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [BlogCategory, setBlogCategory] = useState();
  const [BlogData, setBlogData] = useState();
  const [TagData, setTagData] = useState();
  const [loadingState, setLoadingState] = useState(true);


  useEffect(() => {

    const GetData = async () => {
      const BlogCategory = await FetchGetRequest(`blog-categories`)
      setBlogCategory(BlogCategory)

      const BlogData = await FetchGetRequest(`blogs`)
      setBlogData(BlogData);

      const TagData = await FetchGetRequest(`tags`)

      setTagData(TagData);

    }

    GetData();
  }, [])



  useEffect(() => {
    if (!BlogData || !BlogCategory || !TagData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, BlogData, BlogCategory, TagData])



  return (
    <>
      <Spacing lg='150' md='100' />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {loadingState ? <SkeletonLoading count={4} width={"850px"} height={"600px"} /> : BlogData && BlogData?.results?.length === 0 ?
              <Div className="noDataFound">
                <h4>No Blogs Found!</h4>
              </Div>
              : BlogData && BlogData?.results?.map((item, index) => (
                <Div key={index}>
                  <PostStyle2
                    thumb={item ? item?.banner_image : ""}
                    title={item?.title}
                    subtitle={item?.content}
                    date={item?.created_at}
                    category={item?.category}
                    categoryHref={"/blog"}
                    href={`/blog/blog-details:${item?.id}`}
                  />
                  {BlogData && BlogData?.results?.length > index + 1 && <Spacing lg='95' md='60' />}
                </Div>
              ))}
            <Spacing lg='60' md='40' />
            {loadingState ? <SkeletonLoading count={2} dynamicStyle={{margin:"auto"}} direction={"row"} width={"250px"} height={"60px"} /> : <Pagination setBlogData={setBlogData} BlogData={BlogData} />}
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg='0' md='80' />
            {loadingState ? <SkeletonLoading width={"300px"} height={"700px"} /> : <Sidebar BlogData={BlogData?.results} TagData={TagData} BlogCategory={BlogCategory} />}
          </Div>
        </Div>
      </Div>
    </>
  )
}
