import { FetchGetRequest } from "../components/api";

export const pageTitle = (title) => {
  // return (document.title = title + " - Burning Twig");
}


export const trimUrl = (urlToSplit, endpoint, index) => {
  if (!endpoint) return;
  return endpoint?.split(urlToSplit)[index]
}


// pagination data fetching 
export const FetchNextData = async (BlogData, setBlogData) => {
  const data = await FetchGetRequest(trimUrl("v1/", BlogData?.next, 1));
  setBlogData(data)
}

export const FetchPreviousData = async (BlogData, setBlogData) => {
  const data = await FetchGetRequest(trimUrl("v1/", BlogData?.previous, 1));
  setBlogData(data)
}

export const FetchByPageNo = async (pageNo, setBlogData, filterId, filterType) => {

  const url = filterType && filterId ? `blogs/${filterId}/${filterType}/?page=${pageNo}` : `blogs/?page=${pageNo}`

  const data = await FetchGetRequest(url)
  setBlogData(data)
}



export const checkLength = (data) => {
  if (data){
    return data?.length >= 1
  }else{ 
    return false;
  }
}

// export const reverseData = (data) => {
//   if (!data) return [];
//   if (data.length <= 1) return data;
//   let start = 0;
//   let end = data.length - 1
//   while (start < end) {
//     let temp = data[start];
//     data[start] = data[end];
//     data[end] = temp;
//     start++;
//     end--;
//   }
//   return data
// }