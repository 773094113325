import React from 'react'
import Slider from 'react-slick';
import './logolist.scss'

export default function LogoList({ ClientData }) {



  const checkThePublishedImage = () => {
    if (ClientData) {
      const data = ClientData?.map((item, index) => {
        if (item?.is_published) {
          return item;
        } else {
          return null
        }
      }).filter((item, index) => item !== null)
      if (data.length >= 8) {
        return 7
      } else {
        return data?.length - 1
      }
    }
  }

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    slidesToScroll: 1,
    infinite: true,
    centerPadding: "0",
    slidesToShow: checkThePublishedImage() && checkThePublishedImage(),
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };


  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {ClientData?.map((item, index) => {
        return item?.is_published ? (<div className="cs-partner_logo" key={index}>
          <img src={item.logo} alt={item.name} loading="lazy" />
        </div>) : null
      })}
    </Slider>
  )
}
