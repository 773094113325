import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FetchGetRequest } from '../api';
import { SkeletonLoading } from "../Loading/index"
import Div from '../Div';
import Spacing from '../Spacing';

const FotterPages = () => {
  const title = useParams();
  const pageno = title.fotterPage.split(":")[1]

  const [PagesData, setPagesData] = useState();
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const PagesData = await FetchGetRequest(`pages/${pageno}`)
      setPagesData(PagesData);
    }
    getData();
  }, [pageno])

  // if (!PagesData) return <Loading />

  useEffect(() => {
    if (!PagesData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [PagesData])




  const changeToText = (content) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }


  return (
    <>
      <Spacing lg='150' md='80' />
      <Div className='container'>
        <Div className="row">
          {loadingState ? <SkeletonLoading  dynamicStyle={{ margin: "60px auto" }} count={1} width={'50%'} height={'60px'} /> :
            <h4 className='' style={{ textAlign: "center", fontSize: "50px", fontWeight: "700", color: "#0e4b4a" }}>{PagesData?.title}</h4>}
        </Div>
        {loadingState ? <SkeletonLoading dynamicStyle={{ marginRight: "auto" }} count={5} width={'100%'} height={'500px'} /> : <Div className="">
          {changeToText(PagesData?.content)}
        </Div>}
      </Div>
      <Spacing lg='150' md='80' />
    </>
  )
}

export default FotterPages