import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ModalImage from 'react-modal-image';

export default function MasonryGallery({ CatagoryData, GallaryData }) {
  const [active, setActive] = useState('All');
  const [itemShow, setItemShow] = useState(5);
  const [imageHeight, setImageHeight] = useState([629, 629, 269, 269, 629])


  return (
    <>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Gallery" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'All' ? 'active' : ''}>
                <span onClick={() => setActive('All')}>All</span>
              </li>
              {CatagoryData?.results?.length >= 1 && CatagoryData?.results?.map((item, index) => (

                <li
                  className={active === item?.name ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item?.name)}>
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
      </Div>
      <Spacing lg="90" md="45" />
      <Div className="cs-masonry_4_col">
        {GallaryData && GallaryData?.results?.slice(0, itemShow)?.map((item, index) => {
          return (
            <Div
              className={`${active === "All" ? "" : !(active === item.category.name)
                ? 'd-none'
                : ''
                }`}
              key={index}
            >
              <Div
                className="cs-portfolio cs-style1 cs-type2"
                style={{ height: `${imageHeight[index]}px` }}
              >
                <Div className="cs-lightbox_item">
                  <ModalImage
                    small={item.image}
                    large={item.image}
                    alt={item.title}
                  />
                </Div>
                <Div className="cs-portfolio_hover" />
                <span className="cs-plus" />
                <Div
                  className="cs-portfolio_bg cs-bg"
                  style={{ backgroundImage: `url("${item.image}")` }}
                >
                  <img loading='lazy' src={item?.image} alt="gallary" style={{ height: '100%', width: "100%", objectFit: "cover" }} />
                </Div>
                <Div className="cs-portfolio_info">
                  <Div className="cs-portfolio_info_bg cs-accent_bg" />
                  <Div className="cs-portfolio_subtitle">View Large</Div>
                </Div>
              </Div>
            </Div>
          )
        })}
      </Div>
      <Div className="container">
        <Div className="text-center">
          {GallaryData?.results?.length <= 5 || GallaryData?.results?.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => {
                  setItemShow(itemShow + 4)
                  setImageHeight((item) => ([...item, [629, 629, 269, 269]]))
                }}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
    </>
  );
}
