import React from 'react'
import Div from '../Div'
import './hero.scss';
import ReactPlayer from "react-player/file"
import { SkeletonLoading } from '../Loading';


export default function HeroVideo({ video, loadingState}) {
    return (
        <Div className="video_player_container" id="home">
            {loadingState ? <SkeletonLoading /> : (
                <>
                    <ReactPlayer url={video} loop playing={true} playsinline muted width={"100%"} height={"100%"} />
                    <Div className="video_player_overlay"></Div>
                </>
            )}
        </Div>
    )
}
