import React from 'react'
import "../Loading/loading.scss"
import { useState } from 'react'
import { useEffect } from 'react'

const index = () => {
    return (
        <div className='LoadingPage'>
            {/* <div className="loadingLogo lds-roller">
                <img src="/logo.png" alt="logo" />
            </div> */}
            <div className="redcolor">
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}

export default index

export const SkeletonLoading = ({ height, width, background, count, marginBottom, marginRight, position, Radius, direction, displays, dynamicStyle }) => {
    const styles = {
        width: `${width || "100%"}`, height: `${height || "100%"}`, position: `${position || "relative"}`, overflow: "hidden", display: displays || "flex", flexDirection: `${direction || "column"}`
    }

    const [changeLoadingColor, setChangeLoadingColor] = useState(false);


    useEffect(() => {
        if (typeof window !== "undefined") return;
        const colorMode = document.querySelector(`[data-theme="dark"]`);
        console.log(colorMode)
        if (colorMode) { setChangeLoadingColor(true) } else {
            setChangeLoadingColor(false);
        };
    }, [changeLoadingColor])


    return (<>
        <div style={{ ...styles, ...dynamicStyle }} className="grid_system">
            {Array.from({ length: count || 1 }, () => "").map((item, index) => {
                return (<div key={index} style={{ backgroundColor: `${background || (changeLoadingColor ? "gray" : "#cbcbcb")}`, width: "100%", height: "100%", marginBottom: `${count >= 2 && (direction === "column" || !direction) ? marginBottom || "10px" : ""}`, borderRadius: `${Radius || "7px"}`, marginRight: `${direction === "row" ? marginRight || "10px" : ""}` }} className="main_skeleton_loading loading_background">
                </div>)
            })
            }
        </div>
    </>)
}