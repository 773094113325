import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
// eslint-disable-next-line
import { Link, useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Div from '../Div'
import Sidebar from '../Sidebar'
import Spacing from '../Spacing'
import { useState } from 'react'
import { FetchGetRequest } from '../api'
import Loading, { SkeletonLoading } from "../Loading/index"

export default function BlogDetailsPage() {
  // const params = useParams()
  pageTitle('Blog Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [BlogData, setBlogData] = useState();
  const [BlogCategory, setBlogCategory] = useState();
  const [BlogDatas, setBlogDatas] = useState();
  const [TagData, setTagData] = useState();
  const { blogDetailsId } = useParams();
  const blogId = blogDetailsId.split(":")[1];
  const [loadingState, setLoadingState] = useState(true);


  useEffect(() => {
    const GetData = async () => {
      if (!!blogId) {
        const BlogData = await FetchGetRequest(`blogs/${parseInt(blogId)}`)
        setBlogData(BlogData)
      }

      const BlogCategory = await FetchGetRequest(`blog-categories`)
      setBlogCategory(BlogCategory)

      const BlogDatas = await FetchGetRequest(`blogs`)
      setBlogDatas(BlogDatas);

      const TagData = await FetchGetRequest(`tags`)
      setTagData(TagData);

    }

    GetData();

  }, [blogId]);

  useEffect(() => {
    if (!BlogData || !BlogDatas || !BlogCategory || !TagData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [BlogData, BlogDatas, BlogCategory, TagData])


  // if (!BlogData || !BlogDatas || !BlogCategory || !TagData) {
  //   return <Loading />;
  // }
  const changeToText = (content) => {

    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }

  const createdDate = new Date(BlogData?.created_at)?.toLocaleString()?.split(",")[0];
  return (
    <>
      {/* Start Blog Details */}
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} width={"800px"} height={"600px"} /> :
                  <img src={BlogData?.banner_image} alt="Post" className="w-100 cs-radius_15" />
                }
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} count={2} width={"100px"} height={"60px"} /> : <span className="cs-posted_by">{createdDate}</span>}
                  {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} count={2} width={"100%"} height={"60px"} /> : <Link to={`/blog/filterData/category:${BlogData?.category?.name}/${"category"}`} className="cs-post_avatar">{BlogData?.category?.name}</Link>}
                </Div>
                {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} width={"90%"} height={"100px"} /> : <h2 className="cs-post_title">{BlogData?.title}</h2>}
                {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} width={"90%"} height={"300px"} /> : <div>{changeToText(BlogData?.content)}</div>
                }
                {/* <Div className="row">
                  <Div className="col-md-6">
                    <img src="/images/about_img_1.jpeg" alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img src="/images/about_img_5.jpeg" alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div> */}
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section */}
            <Spacing lg='30' md='30' />
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg='5' md='5' />
            <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
            <Spacing lg='40' md='30' />
            <form className="row">
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            {/* End Comment Section */}
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            {/* Start Sidebar */}
            <Spacing lg='0' md='80' />
            {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} width={"100%"} height={"100%"} /> : <Sidebar BlogData={BlogDatas?.results} TagData={TagData} BlogCategory={BlogCategory} />}
            {/* End Sidebar */}
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start Blog Details */}
    </>
  )
}
