import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'

export default function RecentPost({ title, data }) {


  const [showItem, setShowItem] = useState(3);

  const changeDate = (date) => {
    return new Date(date)?.toLocaleString()?.split(",")[0];
  }



  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <ul className="cs-recent_posts">
        {data && [...data]?.reverse()?.slice(0, showItem).map((item, index) => (
          <li key={index}>
            <Div className="cs-recent_post">
              <Link to={`/blog/blog-details:${item?.id}}`} className="cs-recent_post_thumb">
                <Div className="cs-recent_post_thumb_in cs-bg" style={{ backgroundImage: `url(${item ? item?.banner_image : ""})` }} />
              </Link>
              <Div className="cs-recent_post_info">
                <h3 className="cs-recent_post_title"><Link to={`/blog/blog-details:${item?.id}`}>{item && item?.title?.slice(0, 40)}...</Link></h3>
                <Div className="cs-recent_post_date cs-primary_40_color">{changeDate(item?.created_at)}</Div>
              </Div>
            </Div>
          </li>
        ))}
      </ul>
      <button className='ShowMoreBtn' onClick={() => {
        showItem >= data?.length ?
          setShowItem(showItem - 2)
          :
          setShowItem(showItem + 2)
      }}>
        {showItem >= data?.length ? "Show Less" : "Show More"}
      </button>
    </>
  )
}
