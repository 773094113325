import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'


export default function SideMenuWidget({ title, data }) {


  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <ul className='cs-side_menu_widget'>
        {data && data?.map((item, index) => (
          <li key={index}>
            <Icon icon="material-symbols:keyboard-double-arrow-right-rounded" />
            <Link to={`/blog/filterData/category:${item.name.toLowerCase()}/type:${"category"}`} key={index}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </>
  )
}
