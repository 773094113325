import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import{ SkeletonLoading } from "../Loading/index";
import Spacing from '../Spacing';
import { FetchGetRequest } from '../api';

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('All');
  const [itemShow, setItemShow] = useState(7);
  const [portfolioData, setPortfolioData] = useState();
  const [catagoryData, setCatagoryData] = useState();
  const [loadingState, setLoadingState] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {

    const GetData = async () => {
      const portfolioData = await FetchGetRequest(`portfolio`)
      setPortfolioData(portfolioData)

      const CatagoryData = await FetchGetRequest(`portfolio-categories`)
      setCatagoryData(CatagoryData)

    }

    GetData();
  }, [])



  useEffect(() => {
    if (!portfolioData || !catagoryData) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [loadingState, portfolioData, catagoryData])




  return (
    <>
      <Spacing lg='140' md='100' />
      {/* Start What we do Section */}
      <Div className="container">
        {loadingState ? <SkeletonLoading displays={"block"} dynamicStyle={{ margin: "auto" }} width={"500px"} height={"90px"} /> : <SectionHeading
          title="Some recent work"
          subtitle="What we do"
          variant="cs-style1 text-center"
        />}
      </Div>

      <Spacing lg="60" md="80" />
      <Div className="container text-center">
        <Div className="cs-filter_menu cs-style1 ">
          {loadingState ? <SkeletonLoading dynamicStyle={{ margin: "auto" }} width={"300px"} height={"60px"} /> : <ul className="cs-mp0 cs-center">
            <li className={active === 'All' ? 'active' : ''}>
              <span onClick={() => setActive('All')}>All</span>
            </li>
            {catagoryData?.results?.map((item, index) => (
              <li
                className={active === item.name ? 'active' : ''}
                key={index}
              >
                <span onClick={() => setActive(item?.name)}>
                  {item?.name}
                </span>
              </li>
            ))}
          </ul>}
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {loadingState ? <SkeletonLoading direction={"row"} width={"2000px"} count={4} height={"400px"} /> : portfolioData && portfolioData?.results?.slice(0, itemShow).map((item, index) => {
            return (
              <Div
                className={`${index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
                  } ${active === 'All'
                    ? ''
                    : !(active === item?.category?.name)
                      ? 'd-none'
                      : ''
                  }`}
                key={index}
              >
                <Portfolio
                  title={item.title}
                  subtitle={item.subtitle}
                  href={item.href}
                  src={item.image}
                  variant="cs-style1 cs-type1"
                />
                <Spacing lg="25" md="25" />
              </Div>
            )
          })}
        </Div>

        <Div className="text-center">
          {portfolioData && portfolioData?.results?.length <= itemShow ?
            ''
            : (
              <>
                <Spacing lg="65" md="40" />
                <span
                  className="cs-text_btn"
                  onClick={() => setItemShow(itemShow + 3)}
                >
                  <span>Load More</span>
                  <Icon icon="bi:arrow-right" />
                </span>
              </>
            )}
        </Div>
      </Div>
    </>
  );
}
