import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import Home from './components/Pages/Home';
import Layout from './components/Layout';
import FilterPage from './components/Pages/FilterPage';
import { Toaster } from 'react-hot-toast';
import FotterPages from './components/Pages/FotterMorePages';
import { SkeletonTheme } from 'react-loading-skeleton';

function App() {
  return (
    <>
      <Toaster position='top-right' />
      <SkeletonTheme>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={<Home />}
            />
            <Route path="about" element={<AboutPage />} />
            <Route path="service" element={<ServicesPage />} />
            <Route
              path="service/:serviceDetailsId"
              element={<ServiceDetailsPage />}
            />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route
              path="portfolio/:portfolioDetailsId"
              element={<PortfolioDetailsPage />}
            />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/filterData/:filterId/:filterType" element={<FilterPage />} />
            <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="more/:fotterPage" element={<FotterPages />} />

            {/* <Route path="faq" element={<FaqPage />} /> */}
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </SkeletonTheme>

    </>
  );
}

export default App;
