import React, { useEffect, useState } from 'react';
import Div from '../Div';
import MasonryGallery from '../Gallery/MasonryGallery';
import HeroVideo from '../Hero/HeroVideo';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { checkLength, pageTitle } from '../../helper';
import ServiceList from '../ServiceList';
import LogoList from '../LogoList';
import { FetchGetRequest } from '../api';
import { Helmet } from 'react-helmet-async';
import { SkeletonLoading } from '../Loading';

export default function Home() {
    const [isMobile, setIsMobile] = useState(false);
    const [aboutData, setAboutData] = useState();
    const [GallaryData, setGallaryData] = useState();
    const [ServicesData, setServicesData] = useState();
    const [ClientData, setClientData] = useState();
    const [CatagoryData, setCatagoryData] = useState();
    const [SiteSettingsData, setSiteSettingsData] = useState();
    const [loadingState, setLoadingState] = useState(true);
    pageTitle('Home');

    useEffect(() => {

        window.scrollTo(0, 0);
        const handleWindowResize = () => {
            if (window.innerWidth < 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    // Fetch about-us data 
    useEffect(() => {

        const GetData = async () => {
            const AboutData = await FetchGetRequest(`about-us`)
            setAboutData(AboutData)

            const GallaryData = await FetchGetRequest(`images`)
            setGallaryData(GallaryData);

            const ServicesData = await FetchGetRequest(`services`)
            setServicesData(ServicesData);

            const ClientData = await FetchGetRequest(`client`)
            setClientData(ClientData);

            const CatagoryData = await FetchGetRequest(`image-categories`)
            setCatagoryData(CatagoryData);

            const SiteSettingsData = await FetchGetRequest(`site-settings`)
            setSiteSettingsData(SiteSettingsData);
        }

        GetData();
    }, [])


    useEffect(() => {
        if (!aboutData || !GallaryData || !ServicesData || !CatagoryData || !ClientData || !SiteSettingsData) {
            setLoadingState(true);
        } else {
            setLoadingState(false);
        }
    }, [loadingState, aboutData, GallaryData, ServicesData, CatagoryData, ClientData, SiteSettingsData])


    return (
        <>
            <Helmet>
                <title>Burning Twig - Home</title>
                <meta name='keywords' content={SiteSettingsData && SiteSettingsData[0]?.keywords !== "" ? SiteSettingsData[0]?.keywords : "Burning-twig"} />
                <meta name='description' content={SiteSettingsData && SiteSettingsData[0]?.meta_description !== "" ? SiteSettingsData[0]?.meta_description : `A digital arts company based in Pokhara, creating specialty documentaries and pictures of social organizations. We believe in excellence and quality. Our clients are from all over the world, whereas our brilliant talents are trained and employed locally.”
`} />
                <link rel="shortcut icon" href={SiteSettingsData && SiteSettingsData[0]?.favicon} type='image/*' />
            </Helmet>

            {/* Start Hero Section */}
            {isMobile ? (

                <HeroVideo loadingState={loadingState} key="mobile" video={checkLength(SiteSettingsData) ? SiteSettingsData[0]?.banner_video_mobile : ""} phoneNumber="+044 546664" email="info@burningtwig.com" />

            ) : (
                <HeroVideo loadingState={loadingState} key="desktop" video={checkLength(SiteSettingsData) ? SiteSettingsData[0].banner_video_desktop : ""} phoneNumber="+044 546664" email="info@burningtwig.com" />
            )}
            {/* End Hero Section */}

            <Spacing lg="120" md="60" />
            <Div className="container ">
                <div className="row align-items-center">
                    <h2 className="cs-font_50 cs-m0 cs-line_height_4 text-center">
                        Your Story is an Art
                    </h2>
                </div>
            </Div>

            {/* Start About Section */}
            <Spacing lg="120" md="50" />
            <Div className="container">
                <Div className="row align-items-center cs-column_reverse_lg">
                    <Div className="col-lg-5">
                        <Div style={{ position: "relative" }} className="cs-radius_15 cs-shine_hover_1">
                            {loadingState ? <SkeletonLoading width={"500px"} height={"600px"} /> :
                                <img
                                    src={checkLength(aboutData?.results) ? aboutData?.results[0]?.feature_image : ""}
                                    alt="About"
                                    className="cs-w100 cs-radius_5"
                                    loading="lazy"
                                />
                            }
                        </Div>
                    </Div>
                    <Div className="col-lg-6 offset-lg-1" >
                        {loadingState ? <SkeletonLoading width={"600px"} height={"300px"} /> :
                            <SectionHeading
                                title={checkLength(aboutData?.results) ? (aboutData?.results[0]?.page_heading || '') : ""}
                                subtitle={checkLength(aboutData?.results) ? (aboutData?.results[0]?.page_title || '') : ""}
                                btnText="Learn More"
                                btnLink="/about"
                                loadingState={loadingState}
                            />
                        }
                        <Spacing lg="0" md="40" />
                    </Div>
                </Div>
            </Div>
            {/* End About Section */}


            {/* Start Gallery Section */}
            <Spacing lg="145" md="80" />
            {loadingState ? <SkeletonLoading height={"300px"} /> :
                <MasonryGallery CatagoryData={CatagoryData} GallaryData={GallaryData} />
            }
            {/* End Gallery Section */}


            {/* Start Services Section */}
            <Spacing lg="120" md="80" />
            <Div className="container">
                <SectionHeading
                    title="Our core services"
                    subtitle="Services"
                    variant="cs-style1 text-center"
                />
                <Spacing lg="70" md="45" />
                {loadingState ? <SkeletonLoading  direction={"row"} count={2} height={"300px"} /> :
                    <ServiceList ServicesData={ServicesData} />
                }
            </Div>
            {/* End Services Section */}

            {/* Start LogoList Section */}
            <Spacing lg="120" md="80" />
            <Div className="container">
                <SectionHeading
                    title="Our clients"
                    subtitle="Clients"
                    variant="cs-style1 text-center"
                />
                <Spacing lg="70" md="45" />
                {loadingState ? <SkeletonLoading marginRight={"20px"} direction={"row"} count={7} height={"150px"} /> :
                    <LogoList ClientData={ClientData} />
                }
            </Div>
            <Spacing lg="130" md="80" />
            {/* End LogoList Section */}

        </>
    );
}
