import React, { useState } from 'react'
import Button from '../Button'
import Div from '../Div'
import Spacing from '../Spacing'
import { SkeletonLoading } from '../Loading';

export default function Portfolio3({ title, subtitle, btnText, btnLink, imageUrl, category, loadingState }) {
  const [hovered, setHovered] = useState(false);
  return (
    <Div className="cs-portfolio cs-style2 cs-type1">
      <Div className="cs-gradient_shape" />
      <Div className="container">
        <Div className="row align-items-center cs-column_reverse_lg">
          <Div className="col-xl-5 col-lg-6">
            <Spacing lg='0' md='35' />
            <Div className="cs-section_heading cs-style1">
              {loadingState ? <SkeletonLoading width={"500px"} height={"200px"} dynamicStyle={{ marginBottom: "20px" }} /> : <h3 className="cs-section_subtitle text_justify line_clamp4">{subtitle?.slice(0, 300)}</h3>}
              {loadingState ? <SkeletonLoading width={"500px"} height={"300px"} /> : <h2 className="cs-section_title">{title}</h2>}
              <Spacing lg='45' md='20' />
              {loadingState ? <SkeletonLoading width={"200px"} height={"60px"} /> : <span
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <Button
                  btnLink={btnLink}
                  btnText={btnText}
                />
              </span>}
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Div className={hovered ? "cs-portfolio_img active" : "cs-portfolio_img"}>
              <h3 className="cs-portfolio_img_title">{category}</h3>
              <Div className="cs-portfolio_img_in cs-shine_hover_1 cs-radius_5">{loadingState ? <SkeletonLoading width={"500px"} height={"600px"} /> : <img src={imageUrl} alt="Portfolio" className="cs-w100" />}</Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
